import React from 'react'
import { useRouter } from 'next/router'
import Button from '@/components/Button'

const NotFoundPage = () => {
  const router = useRouter()
  return (
    <div className="flex h-mcontent flex-col items-center justify-center lg:h-content">
      <div className="text-center text-7xl font-bold">404</div>
      <div className="my-12 flex w-full items-center justify-center text-base font-light">这个页面不存在，请输入正确的地址哦</div>
      <div className="flex justify-center">
        <Button
          type="primary"
          onClick={() => {
            router.push('/')
          }}
        >
          返回首页
        </Button>
      </div>
    </div>
  )
}

export default NotFoundPage
